.app-container {
	width: 90%;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	flex-direction: column;
	min-height: 50%;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
	.app-container {
		width: 80%;
	}
}

/*  Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
	.app-container {
		width: 60%;
	}
}
