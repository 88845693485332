main {
	background-position: center;
	height: 900px;
	background-repeat: no-repeat;
	background-size: 100%;
	background-attachment: fixed;
}

body {
	font-family: "Martel Sans";
	color: var(--text-mid);
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Martel";
}

h2.welcome {
	margin: 0;
	line-height: 2.25em;
}

h2.welcome.with-line,
h2.with-line__full {
	width: fit-content;
	margin: 0 auto;
}

h2.with-line:after {
	content: "";
	display: block;
	position: relative;
	width: 50%;
	height: 5px;
	background-color: var(--cyan);
	margin: 0 auto;
}

h2.with-line__full:after {
	content: "";
	display: block;
	position: relative;
	height: 5px;
	background-color: var(--cyan);
	margin: 0 auto;
}

a {
	text-decoration: underline;
	text-decoration-color: var(--primary-color);
	color: var(--text-mid);
}

legend.compound-heading {
	text-align: center;
	margin-bottom: 8px;
}

li {
	margin-bottom: 8px;
}
