.card {
	background-color: #fff;
	margin: 40px 10px 0 10px;
	border-radius: 2px;
	box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%),
		0 1px 5px 0 rgb(0 0 0 / 12%);
	padding: 30px;
}

.card > .card__content {
	margin-left: 0;
	margin-right: 0;
}

.card > .feedback.card__content > * {
	justify-content: center;
	text-align: center;
}

.transparent {
	background-color: rgba(255, 255, 255, 0.85);
	text-align: center;
	color: #4a4a4a;
}

.card .welcome {
	text-align: center;
}

.card .i-agree-radiobutton {
	width: 100%;
	padding: 7px 0 7px 0;
	background-color: #f0f0f0;
	line-height: 1.6rem;
}

/**/
.card hr {
	max-width: 75rem;
	height: 0;
	border-right: 0;
	border-top: 0;
	border-bottom: 1px solid #cacaca;
	border-left: 0;
	margin: 1.25rem auto;
	clear: both;
}

.card h3,
.card .section-title {
	font-size: 30px;
	margin-bottom: 10px;
	text-align: center;
}

.card h4 {
	text-align: center;
	font-size: 1.25rem;
}

.card p {
	font-size: 14px;
	color: #4a4a4a;
	line-height: 1.6;
	margin-bottom: 1rem;
	text-rendering: optimizeLegibility;
}

.card img {
	height: 300px;
	width: auto;
}
.card ul {
	font-size: 14px;
	color: #4a4a4a;
	line-height: 1.6;
	margin-bottom: 1rem;
	text-rendering: optimizeLegibility;
}

.card .price-type {
	font-size: 30px;
	color: var(--primary-color);
	font-weight: 500;
	text-decoration: none;
}

.card .costLine {
	display: flex;
	justify-content: space-between;
	font-weight: 500;
}

.card .costLine p {
	margin-bottom: 0.5em;
	margin-top: 0.5em;
}

.card .icas-radio-button__container {
	padding: 0;
	margin: 0;
	color: #4a4a4a;
}

.card .icas-radio-button__container legend {
	font-size: 14px;
	margin-bottom: 0.1em;
}

.card label.icas-radio-button__label {
	line-height: 23px;
	font-weight: 700;
}

.section.additional-services {
	text-align: center;
}

.section.additional-services .icas-multiselect {
	width: 100%;
	flex-grow: 1;
}

.section.additional-services h3 {
	font-size: 19px;
}

.section.additional-services .icas-multiselect-container__heading {
	color: #4a4a4a;
}

.section.additional-services .icas-multiselect-container__description {
	margin-bottom: 0;
}

.card.regulation .action {
	text-align: center;
}

.card.regulation .practising-certificate__link {
	text-decoration: underline;
	cursor: pointer;
}

.card.regulation .fa-check-circle {
	font-size: 60px;
}

.card.thank-you .card__content {
	display: flex;
	flex-direction: column;
}

.fa-check-circle {
	color: #04a64b;
	font-size: 100px;
}

.card.thank-you .welcome {
	margin-bottom: 0.5em
}

.card.thank-you .detail {
	margin: auto;
}

.card.thank-you .confirmed {
	display: flex;
	align-items: center;
	justify-content: center;
}

.card.thank-you .detail > * {
	text-align: center;
}

.card.thank-you h3 {
	font-size: 19px;
}

label.checkbox-wrapper {
	line-height: 1.6rem;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
	.card .welcome,
	.card .section-title,
	.card .price-type {
		font-size: 36px;
	}

	.card > .card__content {
		margin-left: 55px;
		margin-right: 55px;
	}

	.section.additional-services .icas-multiselect__outer {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;
	}

	.card.thank-you .card__content {
	flex-direction: row;
}
}

/*  Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
	.fa-check-circle {
		font-size: 150px;
	}
}
