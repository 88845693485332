/* star rating */

.star {
	cursor: pointer;
}

.star.outline path {
	fill: #4a4a4a;
}

.star.filled path,
.star.outline:hover path,
.star.outline:focus path {
	fill: #f9b122;
}

#star-rating {
	margin-top: 1.1em;
	margin-bottom: 1.1em;
}

#star-rating:focus-within {
	outline: 1px solid #f9b122;
}

#star-rating legend {
	margin-bottom: 1em;
	font-size: 16px;
}

#star-rating svg {
	font-size: 1.33em;
}

.screen-reader-only {
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
	#star-rating svg {
		font-size: 2em;
	}
}

/*  Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
	#star-rating svg {
		font-size: 3em;
	}
}

/* feedback comment */
#feedback-comment {
	width: 100%;
	box-shadow: none;
	border: 1px solid #cacaca;
	height: 50px;
	resize: none;
	display: block;
	box-sizing: border-box;
	width: 100%;
	padding: 0.5rem;
	margin: 0 0 1rem;
	font-family: inherit;
	font-size: 0.8rem;
	color: #0a0a0a;
	background-color: #fefefe;
	border-radius: 5px;
	transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
	-webkit-appearance: none;
	overflow: auto;
}

#feedback-label {
	display: block;
	text-align: center;
	line-height: 1.5em;
	font-size: 16px;
	padding-bottom: 1.1em;
}

#feedback-length {
	display: block;
	text-align: right;
	font-size: 14px;
	color: #4a4a4a;
}

#feedback-successful {
	display: block;
	text-align: center;
	line-height: 1.5em;
	font-size: 16px;
}
